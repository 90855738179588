<template>
  <div class="space-y-3">
    <div class="flex items-center gap-2">
      <div class="p-2 bg-pink-100 dark:bg-pink-900 rounded">
        <SvgUse
          name="ranking"
          path="/svgs/home.svg"
          class="w-6 h-6 text-pink-500 dark:text-white"
        />
      </div>
      <h4 class="text-xl font-bold">{{ LL.popular_topic() }}</h4>
    </div>
    <USeparator />
    <div class="flex flex-wrap gap-2">
      <UButton
        v-for="t in tags"
        :key="t.id"
        :to="`/posts/topics/${t.slug}`"
        color="neutral"
        variant="soft"
        icon="i-lucide-tags"
      >
        <span>{{ t.title }}</span>
        <span class="text-neutral-400 dark:text-neutral-500">({{ t.count }})</span>
      </UButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { TTopicPopular } from "~/features/post";
import { typesafeI18n } from "~/locales/i18n-vue";

type Props = {
  tags: TTopicPopular[];
};

withDefaults(defineProps<Props>(), {
  tags: () => [],
});

const { LL } = typesafeI18n();
</script>
